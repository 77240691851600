exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-academic-tsx": () => import("./../../../src/pages/academic.tsx" /* webpackChunkName: "component---src-pages-academic-tsx" */),
  "component---src-pages-automotive-tsx": () => import("./../../../src/pages/automotive.tsx" /* webpackChunkName: "component---src-pages-automotive-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-dpa-tsx": () => import("./../../../src/pages/dpa.tsx" /* webpackChunkName: "component---src-pages-dpa-tsx" */),
  "component---src-pages-enterprise-security-tsx": () => import("./../../../src/pages/enterprise-security.tsx" /* webpackChunkName: "component---src-pages-enterprise-security-tsx" */),
  "component---src-pages-enterprise-tsx": () => import("./../../../src/pages/enterprise.tsx" /* webpackChunkName: "component---src-pages-enterprise-tsx" */),
  "component---src-pages-fove-demo-tsx": () => import("./../../../src/pages/fove-demo.tsx" /* webpackChunkName: "component---src-pages-fove-demo-tsx" */),
  "component---src-pages-games-tsx": () => import("./../../../src/pages/games.tsx" /* webpackChunkName: "component---src-pages-games-tsx" */),
  "component---src-pages-get-started-tsx": () => import("./../../../src/pages/get-started.tsx" /* webpackChunkName: "component---src-pages-get-started-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-partner-programs-tsx": () => import("./../../../src/pages/partner-programs.tsx" /* webpackChunkName: "component---src-pages-partner-programs-tsx" */),
  "component---src-pages-press-kit-tsx": () => import("./../../../src/pages/press-kit.tsx" /* webpackChunkName: "component---src-pages-press-kit-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-product-dashboard-tsx": () => import("./../../../src/pages/product/dashboard.tsx" /* webpackChunkName: "component---src-pages-product-dashboard-tsx" */),
  "component---src-pages-product-exitpoll-tsx": () => import("./../../../src/pages/product/exitpoll.tsx" /* webpackChunkName: "component---src-pages-product-exitpoll-tsx" */),
  "component---src-pages-product-objectexplorer-tsx": () => import("./../../../src/pages/product/objectexplorer.tsx" /* webpackChunkName: "component---src-pages-product-objectexplorer-tsx" */),
  "component---src-pages-product-objectives-tsx": () => import("./../../../src/pages/product/objectives.tsx" /* webpackChunkName: "component---src-pages-product-objectives-tsx" */),
  "component---src-pages-product-scene-explorer-tsx": () => import("./../../../src/pages/product/scene-explorer.tsx" /* webpackChunkName: "component---src-pages-product-scene-explorer-tsx" */),
  "component---src-pages-product-scene-viewer-tsx": () => import("./../../../src/pages/product/scene-viewer.tsx" /* webpackChunkName: "component---src-pages-product-scene-viewer-tsx" */),
  "component---src-pages-product-unity-tsx": () => import("./../../../src/pages/product/unity.tsx" /* webpackChunkName: "component---src-pages-product-unity-tsx" */),
  "component---src-pages-product-unreal-analytics-tsx": () => import("./../../../src/pages/product/unreal-analytics.tsx" /* webpackChunkName: "component---src-pages-product-unreal-analytics-tsx" */),
  "component---src-pages-resources-tsx": () => import("./../../../src/pages/resources.tsx" /* webpackChunkName: "component---src-pages-resources-tsx" */),
  "component---src-pages-retail-tsx": () => import("./../../../src/pages/retail.tsx" /* webpackChunkName: "component---src-pages-retail-tsx" */),
  "component---src-pages-terms-of-service-tsx": () => import("./../../../src/pages/terms-of-service.tsx" /* webpackChunkName: "component---src-pages-terms-of-service-tsx" */),
  "component---src-pages-training-tsx": () => import("./../../../src/pages/training.tsx" /* webpackChunkName: "component---src-pages-training-tsx" */),
  "component---src-pages-why-cognitive-3-d-tsx": () => import("./../../../src/pages/why-cognitive3d.tsx" /* webpackChunkName: "component---src-pages-why-cognitive-3-d-tsx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-3-key-benefits-of-using-virtual-reality-for-after-action-review-aar-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/src/content/blog/3-key-benefits-of-using-virtual-reality-for-after-action-review-aar.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-3-key-benefits-of-using-virtual-reality-for-after-action-review-aar-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-3-reasons-why-virtual-reality-is-transforming-retail-analytics-in-2020-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/src/content/blog/3-reasons-why-virtual-reality-is-transforming-retail-analytics-in-2020.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-3-reasons-why-virtual-reality-is-transforming-retail-analytics-in-2020-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-4-enterprise-uses-for-vr-in-the-automotive-industry-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/src/content/blog/4-enterprise-uses-for-vr-in-the-automotive-industry.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-4-enterprise-uses-for-vr-in-the-automotive-industry-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-5-common-mistakes-youre-probably-making-when-training-in-vr-and-how-to-avoid-them-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/src/content/blog/5-common-mistakes-youre-probably-making-when-training-in-vr-and-how-to-avoid-them.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-5-common-mistakes-youre-probably-making-when-training-in-vr-and-how-to-avoid-them-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-5-creative-uses-of-vr-in-the-medical-industry-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/src/content/blog/5-creative-uses-of-vr-in-the-medical-industry.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-5-creative-uses-of-vr-in-the-medical-industry-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-5-top-advantages-of-using-virtual-reality-for-safety-training-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/src/content/blog/5-top-advantages-of-using-virtual-reality-for-safety-training.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-5-top-advantages-of-using-virtual-reality-for-safety-training-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-an-inside-look-how-kent-state-universitys-vr-research-lab-came-together-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/src/content/blog/an-inside-look-how-kent-state-universitys-vr-research-lab-came-together.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-an-inside-look-how-kent-state-universitys-vr-research-lab-came-together-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-cognitive-3-d-and-partners-recognized-with-lumiere-award-for-retail-merchandising-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/src/content/blog/cognitive3d-and-partners-recognized-with-lumiere-award-for-retail-merchandising.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-cognitive-3-d-and-partners-recognized-with-lumiere-award-for-retail-merchandising-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-cognitive-3-d-announces-new-guide-to-help-enterprise-professionals-scale-vr-training-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/src/content/blog/cognitive3d-announces-new-guide-to-help-enterprise-professionals-scale-vr-training.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-cognitive-3-d-announces-new-guide-to-help-enterprise-professionals-scale-vr-training-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-cognitive-3-d-announces-soc-2-compliance-providing-the-highest-standards-of-security-1-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/src/content/blog/cognitive3d-announces-soc-2-compliance-providing-the-highest-standards-of-security-1.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-cognitive-3-d-announces-soc-2-compliance-providing-the-highest-standards-of-security-1-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-cognitive-3-d-in-2022-introducing-the-consumer-closed-beta-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/src/content/blog/cognitive3d-in-2022-introducing-the-consumer-closed-beta.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-cognitive-3-d-in-2022-introducing-the-consumer-closed-beta-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-cognitive-3-d-is-now-available-on-the-unity-asset-store-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/src/content/blog/cognitive3d-is-now-available-on-the-unity-asset-store.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-cognitive-3-d-is-now-available-on-the-unity-asset-store-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-cognitive-3-d-launches-support-for-hp-reverb-g-2-omnicept-edition-the-first-native-biometric-capable-vr-hmd-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/src/content/blog/cognitive3d-launches-support-for-hp-reverb-g2-omnicept-edition-the-first-native-biometric-capable-vr-hmd.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-cognitive-3-d-launches-support-for-hp-reverb-g-2-omnicept-edition-the-first-native-biometric-capable-vr-hmd-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-cognitive-3-d-launches-unified-fixations-system-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/src/content/blog/cognitive3d-launches-unified-fixations-system.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-cognitive-3-d-launches-unified-fixations-system-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-cognitive-releases-dashboard-3-0-transformative-update-to-our-classic-dashboard-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/src/content/blog/cognitive-releases-dashboard-3-0-transformative-update-to-our-classic-dashboard.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-cognitive-releases-dashboard-3-0-transformative-update-to-our-classic-dashboard-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-creating-comfortable-vr-ar-experiences-best-practices-for-developers-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/src/content/blog/creating-comfortable-vr-ar-experiences-best-practices-for-developers.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-creating-comfortable-vr-ar-experiences-best-practices-for-developers-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-delivering-on-retail-merchandising-for-kelloggs-with-mobile-eye-tracking-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/src/content/blog/delivering-on-retail-merchandising-for-kelloggs-with-mobile-eye-tracking.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-delivering-on-retail-merchandising-for-kelloggs-with-mobile-eye-tracking-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-elevate-your-vr-training-with-cognitive-3-d-analytics-and-mindports-vr-builder-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/src/content/blog/elevate-your-vr-training-with-cognitive3d-analytics-and-mindport’s-vr-builder.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-elevate-your-vr-training-with-cognitive-3-d-analytics-and-mindports-vr-builder-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-enterprise-reporting-launched-at-awe-2019-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/src/content/blog/enterprise-reporting-launched-at-awe-2019.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-enterprise-reporting-launched-at-awe-2019-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-feature-highlight-series-active-session-view-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/src/content/blog/feature-highlight-series-active-session-view.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-feature-highlight-series-active-session-view-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-how-fortune-500-companies-are-using-virtual-reality-in-education-and-training-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/src/content/blog/how-fortune-500-companies-are-using-virtual-reality-in-education-and-training.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-how-fortune-500-companies-are-using-virtual-reality-in-education-and-training-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-how-to-use-spatial-analytics-to-scale-research-processes-and-increase-productivity-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/src/content/blog/how-to-use-spatial-analytics-to-scale-research-processes-and-increase-productivity.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-how-to-use-spatial-analytics-to-scale-research-processes-and-increase-productivity-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-innoactive-cognitive-3-d-partner-to-deliver-a-scalable-end-to-end-solution-for-vr-training-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/src/content/blog/innoactive-cognitive3d-partner-to-deliver-a-scalable-end-to-end-solution-for-vr-training.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-innoactive-cognitive-3-d-partner-to-deliver-a-scalable-end-to-end-solution-for-vr-training-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-introducing-cognitive-3-d-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/src/content/blog/introducing-cognitive3d.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-introducing-cognitive-3-d-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-introducing-our-new-plugin-external-log-monitoring-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/src/content/blog/introducing-our-new-plugin-external-log-monitoring.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-introducing-our-new-plugin-external-log-monitoring-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-introducing-the-latest-scene-explorer-update-enhanced-performance-new-features-and-bug-fixes-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/src/content/blog/introducing-the-latest-scene-explorer-update-enhanced-performance-new-features-and-bug-fixes.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-introducing-the-latest-scene-explorer-update-enhanced-performance-new-features-and-bug-fixes-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-introducing-the-objectives-system-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/src/content/blog/introducing-the-objectives-system.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-introducing-the-objectives-system-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-list-of-vr-conferences-2023-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/src/content/blog/list-of-vr-conferences-2023.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-list-of-vr-conferences-2023-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-list-of-xr-ar-and-vr-conferences-2024-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/src/content/blog/list-of-xr-ar-and-vr-conferences-2024.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-list-of-xr-ar-and-vr-conferences-2024-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-new-annotations-feature-for-adding-context-to-your-data-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/src/content/blog/new-annotations-feature-for-adding-context-to-your-data.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-new-annotations-feature-for-adding-context-to-your-data-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-participants-better-understand-human-performance-over-time-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/src/content/blog/participants-better-understand-human-performance-over-time.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-participants-better-understand-human-performance-over-time-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-point-of-interest-system-launches-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/src/content/blog/point-of-interest-system-launches.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-point-of-interest-system-launches-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-programming-in-virtual-reality-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/src/content/blog/programming-in-virtual-reality.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-programming-in-virtual-reality-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-q-a-how-cognitive-3-d-and-varjo-enable-companies-to-improve-immersive-training-and-design-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/src/content/blog/q-a-how-cognitive3d-and-varjo-enable-companies-to-improve-immersive-training-and-design.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-q-a-how-cognitive-3-d-and-varjo-enable-companies-to-improve-immersive-training-and-design-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-room-scale-vr-explained-the-most-important-concept-in-vr-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/src/content/blog/room-scale-vr-explained-the-most-important-concept-in-vr.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-room-scale-vr-explained-the-most-important-concept-in-vr-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-the-practical-uses-of-virtual-reality-vr-eye-tracking-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/src/content/blog/the-practical-uses-of-virtual-reality-vr-eye-tracking.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-the-practical-uses-of-virtual-reality-vr-eye-tracking-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-virtualion-and-cognitive-3-d-partnership-vr-training-from-content-creation-to-analysis-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/src/content/blog/virtualion-and-cognitive-3d-partnership-vr-training-from-content-creation-to-analysis.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-virtualion-and-cognitive-3-d-partnership-vr-training-from-content-creation-to-analysis-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-vr-analytics-what-is-it-and-why-do-you-need-it-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/src/content/blog/vr-analytics-what-is-it-and-why-do-you-need-it.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-vr-analytics-what-is-it-and-why-do-you-need-it-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-what-is-the-metaverse-and-how-to-measure-it-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/src/content/blog/what-is-the-metaverse-and-how-to-measure-it.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-what-is-the-metaverse-and-how-to-measure-it-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-why-proving-roi-from-vr-ar-is-more-important-than-ever-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/src/content/blog/why-proving-roi-from-vr-ar-is-more-important-than-ever.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-why-proving-roi-from-vr-ar-is-more-important-than-ever-md" */)
}

